<template>
  <div class="content">
    <div class="header-img" :style="'background-image: url(' + require('@/assets/header_img.png') + ');'"></div>
    <div class="back-img" :style="'background-image: url(' + require('@/assets/back_img.png') + ');'"></div>
    <div class="footer-img" :style="'background-image: url(' + require('@/assets/footer_img.png') + ');'"></div>
    <div class="content-btn">
      <div class="content-btn-item" :style="'background-image: url(' + require('@/assets/btn_back.png') + ');'" @click="dowApp()">安卓下载通道</div>
    </div>
    <van-popup v-model="show" class="popup-content" position="top" duration="0">
      <van-image class="popup-content-img" :src="require('@/assets/prompt-icon.png')"/>
      <div>请使用手机浏览器打开并下载</div>
    </van-popup>
  </div>
</template>

<script>
import { app } from '@/interfaces/app.js'
export default {
  name: "dowApp",
  data(){
    return {
      show: false
    }
  },
  methods: {
    dowApp(){
      //有邀请码和邀请码
      if(this.$route.query.invite_code && this.$route.query.invite_code !== ''){
        let code = '~dOo0T0^5$*' + this.$route.query.invite_code
        this.$copyText(code)
      }
      // 检查是否为微信浏览器
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.show = true
        return
      }
      // 安卓下载app
      this.$toast.loading("获取版本地址...")
      app().then(res => {
        this.$toast.clear()
        let appDowLink = document.createElement('a')
        appDowLink.style.display = 'none'
        appDowLink.href = res.url
        appDowLink.click()
      }).catch(() => {
        this.$toast.clear()
        this.$toast('获取版本失败，请联系管理员')
      })
    },
    openWeb(){
      window.location.href = 'http://web.douhuofalv.com/login/register?invite_code=' + this.$route.query.invite_code
    }
  }
}
</script>

<style scoped>
.content{
  text-align: center;
  min-height: 100vh;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  background: #1f25ae;
}

/* header */
.header-img,
.back-img,
.footer-img{
  background-position: center;
  background-size: cover;
  position: absolute;
}

.header-img{
  padding-top: 78%;
  top: 0;
  z-index: 2;
  left: 0;
  right: 0;
}

.back-img{
  padding-top: 113%;
  top: 23%;
  z-index: 1;
  left: 0;
  right: 0;
}

.footer-img{
  z-index: 2;
  bottom: 5vh;
  left: 10%;
  right: 10%;
  padding-top: 17%;
}

/* 按钮 */
.content-btn{
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
}

.content-btn-item{
  width: 208px;
  height: 70px;
  line-height: 70px;
  background-position: center;
  background-size: cover;
  margin: 10px auto;
  font-size: 16px;
  color: #1f25ae;
  font-weight: bold;
}

/* 弹出层 */
.popup-content{
  background: transparent;
  text-align: right;
  color: white;
  font-size: 14px;
  padding: 15px;
  box-sizing: border-box;
}

.popup-content-img{
  width: 100px;
  margin-bottom: 20px;
}
</style>
